import { AxiosRequestConfig } from 'axios';
import { Api } from 'src/api';
import { httpConfig } from 'src/lib/modules/http';

const baseUrl = `${httpConfig.options.baseURL}/user-guiding`;

export const payload = (
  userId: string,
  config?: AxiosRequestConfig,
): Promise<{
  attributes: Record<string, string | null | number | boolean>;
  company?: Record<string, string | null | number | boolean>;
}> => Api.http.get(`${baseUrl}/${userId}/payload`, config);
