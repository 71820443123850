import React from 'react';
import { KeyOfObject, ValueOfObject } from 'src/types/generics';
import { getObjectKeys } from 'src/helpers/getObjectKeys';
import { RouteObject as ReactRouterDomObject } from 'react-router-dom';

type ViewRestricted = 'customer' | 'guest';

type RouteObject = ReactRouterDomObject & {
  layout: React.FunctionComponent<any> | React.ComponentClass<any> | React.ComponentType<any>;
  restricted?: ViewRestricted[];
  underMaintenance?: boolean;
};

const appRoutes = {
  root: '/',
  any: '*',
  notFound: '/404',
  maintenance: '/maintenance',
  impersonate: '/impersonate/start',
  login: '/login',
  socialLogin: '/login/:provider/callback',
  register: '/register',
  verifyEmail: '/verify-email',
  authVerifyEmail: '/auth-verify-email',
  forgotPassword: '/forgot-password',
  forgotPasswordReset: '/forgot-password-reset',
  sharedMedia: '/shared-media/:token',
  dashboard: '/dashboard',
  addSubscriptionPlanSelect: '/add-subscription/plan-select',
  addSubscriptionSubscribe: '/add-subscription/subscribe',
  addSubscriptionSubscribeSuccess: '/add-subscription/subscribe-success',
  // klarnaCallback: '/subscribe/klarna/callback',
  company: '/company',
  companyPersonnel: '/company-personnel',
  requestsList: '/requests',
  request: '/requests/request/:requestId',
  requestRevisions: '/requests/request/:requestId/revisions',
  templatesList: '/gallery',
  brandsList: '/brands',
  brand: '/brands/brand/:brandId',
  createBrand: '/brands/create-brand',
  profile: '/profile',
  notificationSettings: '/profile/notifications',
  socialTikTokAuth: '/social/tiktok/callback',
  socialProviderCallback: '/social/:provider/callback',
  repurposing: '/repurposing',
  subscriptionCancelation: '/subscription-cancelation/subscription/:subscriptionId',
} as const;

type AppRoutes = typeof appRoutes;

type AppPaths = ValueOfObject<AppRoutes>;

type AppPathsKeys = KeyOfObject<AppRoutes>;

type AppRoutesObject = Record<AppPathsKeys, RouteObject>;

const excludeRoutes = <T extends AppPathsKeys>(routes: T[]) => {
  return getObjectKeys(appRoutes).reduce(
    (result, route) => {
      if (!routes.includes(route as any)) {
        (result as any)[route] = appRoutes[route];
      }

      return result;
    },
    {} as Omit<AppRoutes, T>,
  );
};

const includeRoutes = <T extends AppPathsKeys>(routes: T[]) => {
  return getObjectKeys(appRoutes).reduce(
    (result, route) => {
      if (routes.includes(route as any)) {
        (result as any)[route] = appRoutes[route];
      }

      return result;
    },
    {} as Pick<AppRoutes, T>,
  );
};

export type { ViewRestricted, RouteObject, AppRoutes, AppRoutesObject, AppPaths, AppPathsKeys };
export { appRoutes, excludeRoutes, includeRoutes };
