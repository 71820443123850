import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AuthUtils } from 'src/services/Auth/Auth.utils';
import { appRoutes, RouteObject } from './routes.types';
import { useAuthStore } from 'src/services/Auth/Auth.store';
import { usePreLoginAuthLocation } from 'src/routes/use-pre-login-auth-location';
import { useSaveHubspotUtmLocationEffect } from 'src/lib/services/hubspot/use-hubspot-saved-utm-location';
import { useProfile } from 'src/models';
import { useAuth } from 'src/services/Auth';

type ProtectedRouteProps = {
  route: RouteObject;
};

// TODO: This component should only be rendered once per route.
export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ route }) => {
  const location = useLocation();
  const { isLoggedIn } = useAuthStore();
  const { rememberLocation } = usePreLoginAuthLocation();
  const profile = useProfile({ enabled: false });
  const { refresh } = useAuth();

  useSaveHubspotUtmLocationEffect();

  useEffect(() => {
    // Invoke refresh token every hour
    const tokenRefreshInvertal = setInterval(() => refresh(), 60 * 60 * 1000);

    return () => {
      clearInterval(tokenRefreshInvertal);
    };
  }, []);

  if (route.underMaintenance) {
    return <Navigate to={appRoutes.maintenance} />;
  }

  if (
    profile.isLoaded &&
    (location.pathname === appRoutes.addSubscriptionPlanSelect ||
      location.pathname === appRoutes.addSubscriptionSubscribe) &&
    !profile?.hasVerifiedEmail
  ) {
    return <Navigate to={appRoutes.authVerifyEmail} state={{ from: location }} replace />;
  }

  // will save location to locale storage in case if login occurs during external login
  if (!isLoggedIn && route.restricted?.includes('guest')) {
    // not logged users in add-subsription page reroute specifically to register page
    if (location.pathname === appRoutes.addSubscriptionSubscribe) {
      return (
        <Navigate to={appRoutes.register} state={{ from: rememberLocation(location) }} replace />
      );
    }

    return <Navigate to={appRoutes.login} state={{ from: rememberLocation(location) }} replace />;
  }

  if (isLoggedIn) {
    const userType = AuthUtils.getPayload()?.user_type;

    if (route.restricted?.includes(userType as any)) {
      return <Navigate to={appRoutes.dashboard} replace />;
    }
  }

  return (
    <route.layout>
      <Outlet />
    </route.layout>
  );
};
