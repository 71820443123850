import { RequestResponse, requestStatus, RequestStatus } from 'src/lib/services/api/request-api';
import { totalLengthToString } from 'src/features/requests/request.helper';
import { ExtraSubtitlesOptions } from 'src/helpers/request_types';

const decorateRequest = (request?: RequestResponse) => {
  if (!request) {
    return request;
  }

  const isOfStatus = (status: RequestStatus | RequestStatus[]): boolean => {
    if (Array.isArray(status)) {
      return status.includes(request?.status as any);
    }

    return status === request?.status;
  };

  const hasRevisions = !!request.revisions_count,
    revisionsCount = request.revisions_count ?? 0,
    latestRevision = [...request.revisions].sort(
      (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    )?.[0],
    isQueued = isOfStatus(requestStatus.queued),
    isCurrentlyEditing = isOfStatus(requestStatus.currentlyEditing),
    isDelivered = isOfStatus(requestStatus.delivered),
    needsReedits = !!request?.requested_reedit_at,
    needsResizes = !!request?.resolutions?.resize?.length,
    needsCaptionsFile = request?.extra?.subtitles === ExtraSubtitlesOptions.separateSrt,
    isApproved = !!request?.approved_at,
    isCompleted = isOfStatus(requestStatus.complete),
    media = request.media?.footage ?? [],
    hasMedia = !!media.length,
    isDefaultFlow = request.flow === 'default',
    hasEditor = !!request.editor,
    editor = request.editor,
    canEdit = isOfStatus([requestStatus.draft, requestStatus.queued]),
    totalLength = totalLengthToString(request.total_length) || request.description?.total_length,
    canRateRevision = !request?.rated_revisions_count,
    canRequestReEdit = isDelivered && !needsReedits && !isApproved,
    needsApproval =
      (needsResizes && !latestRevision?.media?.some((m) => m.collection_name === 'resizes')) ||
      (needsCaptionsFile && !latestRevision?.media?.some((m) => m.collection_name === 'captions')),
    canApprove = isDelivered && !isApproved && !needsReedits && needsApproval,
    canComplete = isDelivered && !needsReedits && !needsApproval;

  return {
    ...request,
    isQueued,
    isCurrentlyEditing,
    isDelivered,
    needsReedits,
    isApproved,
    isCompleted,
    hasRevisions,
    revisionsCount,
    media,
    hasMedia,
    isDefaultFlow,
    hasEditor,
    editor,
    canEdit,
    canRateRevision,
    canRequestReEdit,
    canApprove,
    canComplete,
    totalLength,
    isOfStatus,
  };
};

export type DecoratedRequest = ReturnType<typeof decorateRequest>;
export { decorateRequest };
