import { useQueryClient } from '@tanstack/react-query';
import { userGuiding } from 'src/lib/services/api/user-guiding';
import * as ug from './user-guiding';
import { UserGuiding } from './user-guiding';
import { getProfileData } from 'src/models/profile/useProfile';

type UseUserGuidingInterface = {
  identify: () => Promise<void>;
  update: () => Promise<void>;
  load: () => Promise<UserGuiding>;
  loadAndIdentify: () => Promise<void>;
  pageCapture: () => void;
  shutdown: () => void;
};

export const useUserGuiding = (): UseUserGuidingInterface => {
  const queryClient = useQueryClient();

  const identify = async () => {
    const profile = getProfileData();
    if (!profile?.id) {
      return;
    }

    const payload = await queryClient.fetchQuery({
      queryKey: ['user-guiding-payload', profile.id],
      queryFn: ({ signal }) => userGuiding.payload(profile.id, { signal }),
    });

    const data: Parameters<NonNullable<UserGuiding>['identify']> = [
      profile.id,
      {
        ...payload.attributes,
        company: {
          ...payload.company,
          id: profile.company.id,
        },
      },
    ];

    ug.call('identify', ...data);
  };

  const update = () => identify();

  const load = async () => ug.load();

  const loadAndIdentify = async () => {
    await ug.load();
    await identify();
  };

  const pageCapture = () => ug.call('page');

  const shutdown = () => ug.shutdown();

  return {
    identify,
    update,
    load,
    loadAndIdentify,
    pageCapture,
    shutdown,
  };
};
