import React from 'react';
import { UncontrolledDropdown } from 'reactstrap';
import {
  Text,
  WarningOctagonIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  StyledDropdownMenu,
  StyledDropdownItem,
} from '@becreatives/becreatives-ui';
import {
  RevisionCursorNavLink,
  RevisionFallbackImage,
  RevisionFallbackWrapper,
  RevisionVersionControlButton,
  RevisionMainContentCard,
  RevisionVideoControlRow,
} from './RevisionsContentMain.styled';
import { If } from 'src/components/If';
import { RevisionVersionTooltipPopover } from './RevisionVersionTooltipPopover';
import { useTranslation } from 'react-i18next';
import { ShareMediaButton } from 'src/components/Button';
import { MediaViewer } from 'src/components/MediaCard';
import { getPreferredSource, makeSourceOptions, useMediaDownload } from 'src/features/media';
import RevisionFileDisplayFallback from 'src/assets/images/revision-file-display-fallback.png';
import { RevisionMediaList } from 'src/features/revisions';
import { BxChevronDown } from 'src/components/Boxicons';
import { cn } from 'src/lib/utils';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { CardErrorFallback } from 'src/features/fallback';
import { Skeleton } from 'src/components/ui/skeleton';
import { MediaSignedUrlsQueryProvider } from 'src/features/media/use-media-signed-urls-query-context';
import { RevisionVideoPlayer } from 'src/features/revisions/revision-video-player';
import { AspectRatio } from 'src/components/ui/aspect-ratio';
import { useRequestRevisionsStore } from 'src/features/revisions/use-revisions-context-provider';
import { useDecoratedRequestContext } from 'src/features/requests/use-decorated-request-context';
import { RequestCommentsQueryContextProvider } from 'src/features/comments/use-request-comments';
import { EmptySpaceFallbackImage } from 'src/features/fallback/ui';
import {
  VideoPlayerContextProvider,
  VideoPlayerSkeleton,
} from 'src/features/video-player/video-player';
import { rateRevisionDialogPortalTarget } from 'src/features/revisions/rate-revision-composed-dialog';
import { RevisionChatContent, RevisionChatSection } from 'src/pages/RevisionsPage/revisions-content-main/revision-chat-content';

const RevisionsContentMain: React.FC = () => {
  const { t } = useTranslation('pages/shared');

  const request = useDecoratedRequestContext();
  const {
    selectedRevision,
    selectedRevisionMedia,
    revisions,
    select,
    selectPrevious,
    selectNext,
    canSelectNext,
    canSelectPrevious,
  } = useRequestRevisionsStore();
  const { downloadAll, isDownloading } = useMediaDownload();

  return (
    <div className={'tw-mb-4 tw-grid tw-gap-4 lg:tw-grid-cols-12'}>
      <RevisionMainContentCard
        id={rateRevisionDialogPortalTarget}
        color={'white'}
        className={'tw-mb-0 tw-self-start lg:tw-col-span-7 xl:tw-col-span-8'}
      >
        {/*Content top*/}
        <RevisionVideoControlRow>
          <UncontrolledDropdown className={'me-auto'}>
            <RevisionVersionControlButton tag={'span'}>
              {selectedRevision?.title || t('unset', { ns: 'common' })}
              <BxChevronDown className={'ms-2'} />
            </RevisionVersionControlButton>
            <StyledDropdownMenu>
              {/* Create a copy & reverse to sort by desc */}
              {[...revisions].reverse().map((revision, i) => (
                <StyledDropdownItem
                  key={i}
                  className={cn({
                    'active text-primary': revision.title === selectedRevision?.title,
                  })}
                  onClick={() => {
                    if (selectedRevision?.raw.id !== revision.raw.id) {
                      select(revision);
                    }
                  }}
                >
                  {revision.title}
                </StyledDropdownItem>
              ))}
            </StyledDropdownMenu>
          </UncontrolledDropdown>

          <UncontrolledDropdown>
            <RevisionVersionControlButton className={'ms-auto me-4'} tag={'span'}>
              <i className={'bx bx-cloud-download fs-4 me-2'} />
              Download files
            </RevisionVersionControlButton>
            <StyledDropdownMenu>
              <StyledDropdownItem
                onClick={() => {
                  downloadAll([selectedRevisionMedia!]);
                }}
                disabled={!selectedRevisionMedia || isDownloading}
              >
                <i className={'bx bx-cloud-download fs-3 text-info me-2'} />{' '}
                {selectedRevisionMedia?.file_name ?? '-'}
              </StyledDropdownItem>
              <StyledDropdownItem
                onClick={() => {
                  downloadAll(selectedRevision?.raw?.media ?? []);
                }}
                disabled={!selectedRevision?.hasMedia}
              >
                <i className={'bx bx-cloud-download fs-3 text-info me-2'} /> All current version
                files
              </StyledDropdownItem>
            </StyledDropdownMenu>
          </UncontrolledDropdown>

          <If when={!!selectedRevisionMedia}>
            <ShareMediaButton
              mediaId={selectedRevisionMedia?.id ?? ''}
              className={'fs-5 me-4 pointer text-primary'}
              title={'Share'}
            />
          </If>

          <RevisionVersionTooltipPopover placement={'left'} />
        </RevisionVideoControlRow>

        {/*Content main*/}
        <If
          when={!selectedRevision?.isFirstContact}
          else={
            <RevisionFallbackWrapper>
              <RevisionFallbackImage src={RevisionFileDisplayFallback} />
              <Text color={'gray'} $size={'md'} $md={'xl'} $centeredContent={true}>
                <WarningOctagonIcon className={'me-2'} />
                Create a video revision to add files
              </Text>
            </RevisionFallbackWrapper>
          }
        >
          {/* Revision is not first contact, render video player with selected revision media list */}
          <div className={'tw-flex tw-w-full tw-flex-col tw-gap-2 xl:tw-flex-row'}>
            <RevisionMediaList />

            <If
              when={selectedRevision?.hasMedia}
              else={
                <AspectRatio
                  ratio={16 / 9}
                  className={
                    'tw-flex tw-h-full tw-w-full tw-flex-1 tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-self-start'
                  }
                >
                  <RevisionFallbackImage src={RevisionFileDisplayFallback} />
                  <Text color={'gray'} $size={'md'} $md={'xl'} $centeredContent={true}>
                    <WarningOctagonIcon className={'me-2'} />
                    This revision has no files
                  </Text>
                </AspectRatio>
              }
            >
              {/*If media can't be played, render generic media viewed*/}
              <If
                when={selectedRevisionMedia?.canPlay}
                else={
                  <AspectRatio ratio={16 / 9}>
                    <MediaViewer
                      renderOriginal={true}
                      media={selectedRevisionMedia!}
                      className={
                        'tw-mx-auto !tw-h-full !tw-w-auto !tw-rounded-md !tw-object-contain'
                      }
                    />
                  </AspectRatio>
                }
              >
                <div className={'tw-grow'}>
                  <QueryErrorResetBoundary>
                    {({ reset }) => (
                      <ErrorBoundary
                        fallbackRender={({ error, resetErrorBoundary }: any) => (
                          <CardErrorFallback
                            error={error}
                            resetErrorBoundary={resetErrorBoundary}
                          />
                        )}
                        onReset={reset}
                      >
                        <React.Suspense fallback={<VideoPlayerSkeleton />}>
                          <MediaSignedUrlsQueryProvider id={selectedRevisionMedia!.id}>
                            <VideoPlayerContextProvider
                              meta={{
                                fps: selectedRevisionMedia?.fps,
                                durationInSeconds: selectedRevisionMedia?.durationInSeconds,
                              }}
                              defaultSrc={getPreferredSource(selectedRevisionMedia!)}
                              srcOptions={makeSourceOptions(selectedRevisionMedia!)}
                            >
                              <RevisionVideoPlayer />
                            </VideoPlayerContextProvider>
                          </MediaSignedUrlsQueryProvider>
                        </React.Suspense>
                      </ErrorBoundary>
                    )}
                  </QueryErrorResetBoundary>
                </div>
              </If>
            </If>
          </div>
        </If>

        {/*Content bottom*/}
        <RevisionVideoControlRow className={'my-2'}>
          <RevisionCursorNavLink
            color={'primary'}
            disabled={!canSelectPrevious}
            onClick={selectPrevious}
          >
            <ChevronLeftIcon color={'primary'} className={'me-2'} />
            {t('revisions.revision.action.prev')}
          </RevisionCursorNavLink>

          <RevisionCursorNavLink color={'primary'} disabled={!canSelectNext} onClick={selectNext}>
            {t('revisions.revision.action.next')}
            <ChevronRightIcon color={'primary'} className={'ms-2'} />
          </RevisionCursorNavLink>
        </RevisionVideoControlRow>
      </RevisionMainContentCard>

      {/* Request revision chat */}
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary
            fallbackRender={({ error, resetErrorBoundary }: any) => (
              <RevisionChatSection className={'lg:tw-col-span-5 xl:tw-col-span-4'}>
                <EmptySpaceFallbackImage className={'tw-mt-auto tw-self-center'} />
                <CardErrorFallback
                  className={'tw-mt-auto tw-w-full tw-border-none tw-shadow-none'}
                  error={error}
                  resetErrorBoundary={resetErrorBoundary}
                  description={t('alerts:error.failed_to_load_comments', {
                    defaultValue: 'Failed to load comments',
                  })}
                />
              </RevisionChatSection>
            )}
            onReset={reset}
          >
            <React.Suspense
              fallback={
                <Skeleton className={'tw-h-44 lg:tw-col-span-5 lg:tw-h-auto xl:tw-col-span-4'} />
              }
            >
              <RequestCommentsQueryContextProvider
                request={request.id}
                filters={{
                  limit: 50,
                  revision_index: selectedRevision?.raw?.index,
                }}
              >
                <RevisionChatSection className={'lg:tw-col-span-5 xl:tw-col-span-4'}>
                  <RevisionChatContent />
                </RevisionChatSection>
              </RequestCommentsQueryContextProvider>
            </React.Suspense>
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
    </div>
  );
};

export { RevisionsContentMain };
