import React from 'react';
import { useRequestCommentsReadonlyContext } from 'src/features/comments/use-request-comments';
import { useDecoratedRequestContext } from 'src/features/requests/use-decorated-request-context';
import { useRequestRevisionsStore } from 'src/features/revisions/use-revisions-context-provider';
import { Avatar, AvatarFallback, AvatarImage } from 'src/components/ui/avatar';
import { If } from 'src/components/If';
import { Button } from 'src/components/ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/ui/tooltip';
import { Badge } from 'src/components/ui/badge';
import { formatTime } from 'src/features/video-player/video-player-utils';
import { useVideoTimeFormat } from 'src/features/video-player/video-player';
import { useFormatter } from 'src/services/i18n/useFormatter';
import { cn } from 'src/lib/utils';
import { useVideoPlayerProgress } from 'src/features/video-player/use-video-player-progress';
import { CommentResponse } from 'src/lib/services/api/comment-api';

const RevisionVideoCommentSeekButton: React.FC<{
  comment: CommentResponse & {
    sender?: {
      image_url?: string;
    };
  };
  totalDuration: number;
  className?: string;
}> = ({ comment, className, totalDuration }) => {
  const { seekSeconds } = useVideoPlayerProgress();
  const [timeFormat] = useVideoTimeFormat();
  const { selectedRevisionMedia } = useRequestRevisionsStore();
  const { formatDateTime } = useFormatter();

  const durationPercent =
    comment.revision_timestamp! > 0 ? (comment.revision_timestamp! / totalDuration) * 100 : 0;

  const senderName = comment.sender?.full_name || 'Unknown';

  const senderInitials = senderName
    .split(' ')
    .slice(0, 2)
    .map((n) => n?.[0]?.toUpperCase())
    .join('');

  const timestamp = formatTime(comment.revision_timestamp!, {
    format: timeFormat,
    fps: selectedRevisionMedia?.fps ?? 24,
  });

  return (
    <div
      className={'tw-absolute tw-flex tw-w-0 tw-justify-center'}
      style={{
        left: `${durationPercent}%`,
      }}
    >
      <TooltipProvider>
        <Tooltip delayDuration={0}>
          <TooltipTrigger asChild>
            <Avatar
              className={cn('tw-h-5 tw-w-5 tw-cursor-pointer', className)}
              onClick={() => {
                if (Number.isFinite(comment.revision_timestamp)) {
                  seekSeconds(comment.revision_timestamp!);
                }
              }}
            >
              {!!comment?.sender?.image_url && (
                <AvatarImage src={comment.sender.image_url} alt={senderName} />
              )}
              <AvatarFallback asChild className={'tw-text-xs'}>
                <Button
                  className={
                    comment.sender?.me ? 'hover:tw-bg-neutral-200' : 'hover:tw-bg-brand-subtle'
                  }
                  variant={comment.sender?.me ? 'muted' : 'brandSubtle'}
                  size={'unset'}
                >
                  {senderInitials}
                </Button>
              </AvatarFallback>
            </Avatar>
          </TooltipTrigger>
          <TooltipContent className={'tw-flex tw-flex-col tw-gap-2'}>
            <p className={'tw-flex tw-flex-col tw-gap-2 sm:tw-flex-row'}>
              {senderName}
              <i>{formatDateTime(comment.created_at)}</i>
            </p>
            <p>{comment.comment}</p>
            <Badge className={'tw-self-start tw-rounded-sm'} variant={'brand'}>
              {timestamp}
            </Badge>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};

type RevisionVideoCommentsProps = {
  duration: number;
};

export const RevisionVideoComments: React.FC<RevisionVideoCommentsProps> = ({ duration }) => {
  const request = useDecoratedRequestContext();
  const store = useRequestRevisionsStore();

  const { data } = useRequestCommentsReadonlyContext(request.id, {
    limit: 50,
    revision_index: store.selectedRevision?.raw.index,
  });

  const comments =
    data?.pages
      .reduce((a, b) => a.concat(b.items), [] as CommentResponse[])
      ?.filter(
        (c) =>
          store.selectedRevision?.id === c.revision &&
          c.media_id === store.selectedRevisionMedia?.id &&
          Number.isFinite(c.revision_timestamp),
      )
      .sort((a, b) => a.revision_timestamp! - b.revision_timestamp!) ?? [];

  return (
    <If when={!!comments.length}>
      <div className={'tw-relative tw-mt-1 tw-h-5 tw-w-full'}>
        {comments.map((comment, i) => (
          <RevisionVideoCommentSeekButton
            key={i}
            totalDuration={duration}
            comment={comment}
            className={`hover:tw-z-[${i + 1}] hover:tw-z-[100]`}
          />
        ))}
      </div>
    </If>
  );
};
