// @ts-nocheck
import * as ENV from 'src/env.json';
import { KeyOfObject, ValueOfObject } from 'src/types/generics';
import isFunction from 'lodash/isFunction';

declare global {
  interface Window {
    userGuiding?: {
      previewGuide: (
        guideId: string,
        options?: {
          initialStep?: number;
          checkHistory?: boolean;
        },
      ) => void;
      finishPreview: () => void;
      launchChecklist: (
        checklistId: string | number,
        options?: {
          itemsShown?: boolean;
        },
      ) => void;
      hideChecklist: () => void;
      expandChecklist: () => void;
      collapseChecklist: () => void;
      getChecklistCompletionInfo: () => void;
      launchResourceCenter: (
        resourceCenterId: string,
        options?: {
          bypassFilters?: boolean;
          sidebarOpen?: boolean;
        },
      ) => void;
      hideResourceCenter: () => void;
      expandResourceCenter: () => void;
      collapseResourceCenter: () => void;
      launchSurvey: (surveyId: string) => void;
      hideSurvey: () => void;
      launchProductUpdates: () => void;
      hideProductUpdates: () => void;
      launchKnowledgeBase: () => void;
      hideKnowledgeBase: () => void;
      identify: (
        userId: string,
        attributes?: {
          email?: string;
          name?: string;
          created_at?: number;
          company?: {
            id: string;
            name?: string;
            created_at?: number;
          } & Record<string, any>;
        } & Record<string, any>,
      ) => void;
      /**
       * Get the latest attributes of the user. This is handy when you send attributes through a server-side integration and need a user's attribute updated on the front end.
       */
      forceIdentify: () => void;
      page: () => void;
    };
    userGuidingLayer: any[];
    userGuidingSettings?: {
      disablePageViewAutoCapture?: boolean;
    };
  }
}

window.userGuidingSettings = {
  disablePageViewAutoCapture: false,
};

export type UserGuiding = Window['userGuiding'];

const APP_ID = ENV.USER_GUIDING_APP_ID;

const isSdkInjected = (doc: typeof document, src: string) => {
  if (doc && 'querySelector' in doc) {
    return !!doc.querySelector(`script[src="${src}"]`);
  }
};

let IS_SDK_LOADED = false;

export const load = async (
  g = window,
  u = document,
  i = 'script',
  d = 'userGuiding',
  e = 'userGuidingLayer',
  s = APP_ID,
): Promise<UserGuiding> => {
  if (!APP_ID) {
    return;
  }

  const sdkScr = 'https://static.userguiding.com/media/user-guiding-' + s + '-embedded.js';
  if (isSdkInjected(u, sdkScr)) {
    return g[d];
  }

  g[e] = g[e] || [];
  var f = u.getElementsByTagName(i)[0];
  var k = u.createElement(i);
  k.async = true;
  k.src = 'https://static.userguiding.com/media/user-guiding-' + s + '-embedded.js';

  return new Promise<UserGuiding>((resolve, reject) => {
    if (!g[d]) {
      console.debug('setting up user guiding');

      var ug = (g[d] = { q: [] });
      ug.c = function (n) {
        return function () {
          ug.q.push([n, arguments]);
        };
      };
      var m = [
        'previewGuide',
        'finishPreview',
        'track',
        'identify',
        'hideChecklist',
        'launchChecklist',
      ];
      for (var j = 0; j < m.length; j += 1) {
        ug[m[j]] = ug.c(m[j]);
      }
    }

    k.onload = () => {
      console.log('user guiding has loaded CUSTOM PROMISE', g[d]);
      IS_SDK_LOADED = true;
      resolve(g[d]);
    };

    k.onerror = (err) => {
      reject(err);
    };

    f.parentNode.insertBefore(k, f);
  });
};

export const event = {
  onload: 'onload',
  onPreviewStart: 'onPreviewStart',
  onPreviewStep: 'onPreviewStep',
  onPreviewEnd: 'onPreviewEnd',
  onPreviewComplete: 'onPreviewComplete',
  onHotspotInteract: 'onHotspotInteract',
  onHotspotDismiss: 'onHotspotDismiss',
  onChecklistComplete: 'onChecklistComplete',
  onChecklistItemTrigger: 'onChecklistItemTrigger',
  onChecklistUrlClick: 'onChecklistUrlClick',
  onChecklistDismiss: 'onChecklistDismiss',
  onResourceCenterGuideTrigger: 'onResourceCenterGuideTrigger',
  onResourceCenterSurveyTrigger: 'onResourceCenterSurveyTrigger',
  onResourceCenterChecklistGuideTrigger: 'onResourceCenterChecklistGuideTrigger',
  onResourceCenterChecklistUrlClick: 'onResourceCenterChecklistUrlClick',
  onResourceCenterArticleLinkClick: 'onResourceCenterArticleLinkClick',
  onResourceCenterExternalUrlClick: 'onResourceCenterExternalUrlClick',
  onSurveyView: 'onSurveyView',
  onSurveyQuestionView: 'onSurveyQuestionView',
  onSurveyAnswer: 'onSurveyAnswer',
  onIdentificationComplete: 'onIdentificationComplete',
} as const;

export const addEventListener = (
  event: ValueOfObject<typeof event>,
  callback: (data: any) => void,
) => {
  window.userGuidingLayer?.push({ event, callback });
};

export const call = <Method extends KeyOfObject<NonNullable<UserGuiding>>>(
  fn: Method,
  ...args: Parameters<NonNullable<UserGuiding>[Method]>
) => {
  if (window.userGuiding && fn in window.userGuiding && isFunction(window.userGuiding[fn])) {
    try {
      window.userGuiding[fn](...args);
    } catch (e: any) {
      console.error('Caught user guiding error', {
        method: fn,
        args,
        error: e,
      });
    }
  } else {
    console.warn('UserGuiding method not found', fn);
  }
};

export const shutdown = () => {
  call('hideChecklist');
  call('hideSurvey');
  call('hideKnowledgeBase');
  call('hideProductUpdates');
  call('hideResourceCenter');
};

export const hardShutdown = () => {
  shutdown();
  // TODO: remove SDK? There's no real way of reseting the user state for SDK e.g. on logout
};
