import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { UploadInputProps } from '../s3-upload-input.types';
import { resolveValidationRule } from '../../Form.validator';
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/ui/popover';
import { useToggle } from 'src/lib/state-utils';
import { cn } from 'src/lib/utils';
import { If } from 'src/components/If';
import { DropzonePlaceholder } from './dropzone-placeholder';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useUpload } from './use-upload';

const DropzonePopoverInput: React.FC<UploadInputProps> = ({
  name,
  model,
  collection_name,
  minSize,
  maxSize,
  maxItems,
  maxGroupSize,
  children,
  ...props
}) => {
  const { t } = useTranslation();

  const { generateUpload, upload } = useUpload();
  const [open, setOpen] = useToggle();
  const { setError, clearErrors } = useFormContext();
  const { fields, append } = useFieldArray({
    name,
  });

  const [uploadedFiles, setUploadedFiles] = useState<any[]>([...fields]);

  const validate = (files: FileList): void => {
    const errors: any[] = [];

    resolveValidationRule(maxItems, (limit) => `Can't upload more than ${limit} files.`).validate(
      (limit, error) => {
        if (files.length + uploadedFiles.length > limit) {
          errors.push(error);
        }
      },
    );

    resolveValidationRule(
      maxSize,
      (size) => `Can't upload larger than ${size} bytes files.`,
    ).validate((size, error) => {
      if (Array.from(files).find((file) => file.size > size)) {
        errors.push(error);
      }
    });

    resolveValidationRule(
      minSize,
      (size) => `Can't upload smaller than ${size} bytes files.`,
    ).validate((size, error) => {
      if (Array.from(files).find((file) => file.size < size)) {
        errors.push(error);
      }
    });

    resolveValidationRule(
      maxGroupSize,
      (size) => `Your files upload exceeded allowed limit. Allowed limit is ${size} bytes.`,
    ).validate((size, error) => {
      const filesSize = Array.from(files).reduce((acc, item) => {
        return acc + item.size;
      }, 0);
      const uploadedFilesSize = uploadedFiles.reduce((acc, item) => {
        return acc + item.size;
      }, 0);

      if (filesSize + uploadedFilesSize > size) {
        errors.push(error);
      }
    });

    if (errors.length) {
      throw new Error(errors.join('\n'));
    }
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.disabled) {
      return;
    }

    clearErrors(name);

    const files = e.currentTarget.files!;

    try {
      await uploadFiles(files);
    } catch (e: any) {
      setError(name, { type: 'custom', message: e.message });
    }
  };

  const uploadFiles = async (files: FileList) => {
    validate(files);
    const promises = [];

    for (let i = 0; i < files.length; i++) {
      promises.push(generateUpload(model, files[i], collection_name));
    }

    const multipartUploads = await Promise.all(promises);

    multipartUploads.forEach((multipartUpload, index) => {
      // append form value
      append({
        id: multipartUpload.id,
        file_name: multipartUpload.file_name,
        mime_type: multipartUpload.mime_type,
        filePreviewUrl: URL.createObjectURL(files[index]),
      });
      setUploadedFiles((prev) => [...prev, multipartUpload]);
      upload(multipartUpload);
      setOpen(false);
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async (files) => {
      if (props.disabled) {
        return;
      }
      try {
        await uploadFiles(files as any);
      } catch (e: any) {
        setError(name, { type: 'custom', message: e.message });
      }
    },
  });

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent>
        <input
          {...getInputProps()}
          type={'file'}
          multiple
          className={'tw-hidden'}
          accept={props.accept}
          onChange={handleChange}
        />

        <div
          className={cn(
            'tw-relative tw-rounded-lg tw-border tw-border-dashed tw-border-brand-500 tw-bg-neutral-50 tw-transition-colors',
            {
              'tw-cursor-pointer': !fields.length,
              'tw-border-solid tw-border-brand': isDragActive,
            },
            props.disabled && 'tw-cursor-not-allowed tw-opacity-60',
          )}
        >
          <div className={cn('tw-max-h-[628px] tw-min-h-[320px]')} {...getRootProps()}>
            <If
              when={isDragActive}
              else={
                <DropzonePlaceholder>
                  <span className={'tw-text-center tw-text-text-subtle'}>
                    {t('Click to browse or drag and drop files here')}
                  </span>
                </DropzonePlaceholder>
              }
            >
              <DropzonePlaceholder text={t('Drop files here to upload')!} />
            </If>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export { DropzonePopoverInput };
