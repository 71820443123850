import React, { useEffect } from 'react';
import { useUserGuiding } from 'src/lib/services/user-guiding/use-user-guiding';
import { Broadcast } from 'src/broadcast';

export const UserGuidingPingProvider: React.FC = () => {
  const userGuiding = useUserGuiding();

  useEffect(() => {
    Broadcast.user.userGuidingUserPayloadPublished.setListener(userGuiding.update);
    return () => {
      Broadcast.user.userGuidingUserPayloadPublished.removeListener();
    };
  }, []);

  return null;
};
