import { useMutation, useQueryClient } from '@tanstack/react-query';
import { requestClient, requestStatus } from 'src/lib/services/api/request-api';
import { requestQueryKey } from 'src/features/requests/request-query-key';
import { RequestQueryApiResponse } from 'src/features/requests/use-request-query-context';
import { reactQueryClient } from 'src/providers/ReactQueryClientProvider';

type RequestMutations = {
  approve: ReturnType<typeof useMutation<void, unknown, string, unknown>>;
  requestReedit: ReturnType<typeof useMutation<void, unknown, string, unknown>>;
  undoReeditRequest: ReturnType<typeof useMutation<RequestQueryApiResponse, any, string, unknown>>;
  complete: ReturnType<typeof useMutation<void, unknown, string, unknown>>;
  setQueryData: (
    request: string,
    updater: Parameters<typeof reactQueryClient.setQueryData<RequestQueryApiResponse>>[1],
  ) => void;
  isAnyMutationLoading: boolean;
};

export const useRequestMutations = (): RequestMutations => {
  const client = useQueryClient();

  const setQueryData = (
    request: string,
    updater: Parameters<typeof client.setQueryData<RequestQueryApiResponse>>[1],
  ) => client.setQueryData<RequestQueryApiResponse>(requestQueryKey.self(request), updater);

  const approve = useMutation({
    mutationFn: (request: string) => requestClient.approve(request),
    onMutate: async (request) => {
      setQueryData(request, (prev) => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          approved_at: new Date().toISOString(),
        };
      });
    },
    onSuccess: async (response, request) => {
      client.invalidateQueries(requestQueryKey.self(request));
    },
  });

  const requestReedit = useMutation({
    mutationFn: (request: string) => requestClient.requestReedit(request),
    onMutate: async (request) => {
      setQueryData(request, (prev) => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          requested_reedit_at: new Date().toISOString(),
        };
      });
    },
    onSuccess: async (response, request) => {
      client.refetchQueries({
        queryKey: requestQueryKey.self(request),
      });
    },
  });

  const undoReeditRequest = useMutation({
    mutationFn: (request: string) => requestClient.undoReeditRequest(request),
    onMutate: async (request) => {
      setQueryData(request, (prev) => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          requested_reedit_at: undefined,
        };
      });
    },
    onSuccess: async (response, request) => {
      client.refetchQueries({
        queryKey: requestQueryKey.self(request),
      });
    },
  });

  const complete = useMutation({
    mutationFn: (request: string) => requestClient.complete(request),
    onMutate: async (request) => {
      setQueryData(request, (prev) => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          status: requestStatus.complete,
          status_changed_at: new Date().toISOString(),
        };
      });
    },
    onSuccess: async (response, request) => {
      client.invalidateQueries(requestQueryKey.self(request));
    },
  });

  const isAnyMutationLoading =
    complete.isLoading ||
    approve.isLoading ||
    requestReedit.isLoading ||
    undoReeditRequest.isLoading;

  return {
    approve,
    requestReedit,
    undoReeditRequest,
    complete,
    setQueryData,
    isAnyMutationLoading,
  };
};
