import React from 'react';
import { useRequestRevisionsStore } from 'src/features/revisions/use-revisions-context-provider';
import { MinimalMediaCard } from 'src/components/MediaCard/minimal-media-card';
import { cn } from 'src/lib/utils';

type Props = React.PropsWithChildren<React.ComponentPropsWithoutRef<'div'>>;

const RevisionMediaList: React.FC<Props> = ({ children, ...props }) => {
  const { setSelectedRevisionMedia, selectedRevisionMedia, selectedRevision } =
    useRequestRevisionsStore();

  return (
    <div
      {...props}
      className={cn(
        'tw-relative tw-flex tw-h-36 tw-w-full tw-gap-3 tw-rounded-md xl:tw-h-auto xl:tw-w-32 xl:tw-flex-col',
        props.className,
      )}
    >
      <div
        className={
          'gap-2 px-2 px-md-0 py-2 tw-absolute tw-inset-0 tw-flex tw-items-end tw-overflow-x-auto xl:tw-flex-col xl:tw-items-center xl:tw-overflow-y-auto'
        }
      >
        {selectedRevision?.media?.map((m) => (
          <div
            key={m?.id}
            className={cn('tw-cursor-pointer tw-p-2', {
              'tw-rounded-md': selectedRevisionMedia?.id === m?.id,
            })}
            style={{
              backgroundColor: selectedRevisionMedia?.id === m?.id ? '#E8F1FF' : undefined,
              border:
                selectedRevisionMedia?.id === m?.id ? '1px solid #2779C6' : '1px solid transparent',
              transition: 'all 0.25s ease-in-out',
            }}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedRevisionMedia(m?.id);
            }}
          >
            <MinimalMediaCard showTitle canView={false} media={m!} />
          </div>
        ))}
      </div>
      {children}
    </div>
  );
};

export type { Props as RevisionMediaListProps };
export { RevisionMediaList };
