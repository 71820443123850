import React from 'react';
import { UploadCloud } from 'lucide-react';
import { useTranslation } from 'react-i18next';

type Props = {
  text?: string;
  children?: React.ReactNode;
};

const DropzonePlaceholder: React.FC<Props> = ({ text, children }) => {
  const { t } = useTranslation();

  return (
    <div
      className={
        'tw-absolute tw-flex tw-h-full tw-w-full tw-select-none tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-p-4'
      }
    >
      <UploadCloud className={'tw-text-brand-500'} size={32} />
      <span className={'tw-font-medium tw-text-text-strong'}>
        {text || t('actions:file_upload', { defaultValue: 'Upload a file' })}
      </span>
      {children}
    </div>
  );
};

export { DropzonePlaceholder };
